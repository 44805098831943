import React from "react";
let flavour_strings: { [key: string]: string } = {};
try {
  flavour_strings = require(`./${process.env.REACT_APP_FLAVOUR}`).default;
} catch {}

console.log("FOO", flavour_strings);

const createEnglishStrings = <T extends string>(keys: T[]) =>
  Object.fromEntries(keys.map(key => [key, key])) as any as { [key in T]: string };

export const defaultStrings = {
  ...createEnglishStrings([
    "Airpay POS",
    "Choose Business",
    "Inventory & Customers",
    "Airpay POS Help",
    "product",
    "products",
    "Product",
    "Products",
    "customer",
    "customers",
    "Customer",
    "Customers",
    "Category",
    "Categories",
    "category",
    "categories",
    "Discount",
    "Discounts",
    "discount",
    "discounts",
    "Surcharge",
    "Surcharges",
    "surcharge",
    "surcharges",
    "modifier set",
    "modifier sets",
    "Modifier Set",
    "Modifier Sets",
    "modifier",
    "modifiers",
    "Modifier",
    "Modifiers",
    "invoice tag",
    "invoice tags",
    "Invoice Tag",
    "Invoice Tags",
    "invoice",
    "invoices",
    "Invoice",
    "Invoices",
    "business",
    "Business",
    "user",
    "users",
    "User",
    "Users",
    "role",
    "roles",
    "Role",
    "Roles",

    "Paid",

    // icons
    "fa-address-book",
  ]),
  ...{
    "Airpay POS": process.env.REACT_APP_NAME,
    "Airpay POS Help": `${process.env.REACT_APP_NAME} Help`,
    product: "item",
    products: "items",
    Product: "Item",
    Products: "Items",
    customer_disclaimer:
      "As the merchant we will hold and use your personal information for invoicing and other directly related purposes. Talk to us if you want to access or correct your personal information.",
  },
  ...flavour_strings,
};

export type StringsMap = typeof defaultStrings;

export const HospitalityModeStrings: Partial<StringsMap> = {
  "Inventory & Customers": "Menu Items & Tables",

  // General
  product: "menu item",
  products: "menu items",
  Product: "Menu Item",
  Products: "Menu Items",
  customer: "table",
  customers: "tables",
  Customer: "Table",
  Customers: "Tables",
  invoice: "order",
  invoices: "invoices",
  Invoice: "Order",
  Invoices: "Orders",

  // Icons
  "fa-address-book": "fa-grip-horizontal",
};

export const DPMStrings: StringsMap = {
  ...defaultStrings,
  "Airpay POS": "Donation Point Mobile",
  product: "preset",
  products: "presets",
  Product: "Preset",
  Products: "Presets",
  Paid: "History",
};
export const SamsungStrings: StringsMap = {
  ...defaultStrings,
  "Airpay POS": "Samsung POS",
};

export type TranslationFn = (format: string, ...args: any[]) => string;

export const StringsContext = React.createContext<{ strings: StringsMap; tl: TranslationFn }>({
  strings: defaultStrings,
  tl: () => {
    throw new Error("Must be called from inside a StringsContext provider.");
  },
});

export const StringsProvider = ({ children, strings }: { children: React.ReactElement; strings: StringsMap }) => {
  // Set the global variable to avoid needing to use context in the translation methods

  const tl: TranslationFn = (format, ...args) => {
    format = strings[format as keyof StringsMap] || format;
    let i = 0;
    return format?.replace(/%s/g, () => args[i++]);
  };

  return <StringsContext.Provider value={{ strings, tl }}>{children}</StringsContext.Provider>;
};
